import React from "react";
import logo from './digilabsports-logo.png'
import './header.css'

const LandingHeader = () => {

    return(
        <div className="header_main">
            <img src={logo} alt="DigilabSports Logo" className="logo"/>
        </div>
    )
}

export default LandingHeader;