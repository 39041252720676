import React from "react";
import { Link } from "react-router-dom";
import LandingHeader from "./landingHeader";
import "./landing.css";
import logo from "../AthlioConnect/images/logoDarknoDigilab.png";

const MainLandingPage = () => {
  return (
    <div>
      {/* Header Section */}
      <LandingHeader />

      {/* Hero Section */}
      <section className="hero-section">
        <h1>Welcome to DigilabSports</h1>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta-section">
        <h2>AthlioConnect</h2>
        <div className="cta-button-section">
          <Link to="/AthlioConnect">
            <button className="cta-button-black">
              Learn about AthlioConnect
            </button>
          </Link>
          <Link to="https://athlioconnect.com">
            <button className="cta-button-black">
              <img src={logo} alt="AthlioConnect Logo" className="logo" />
              <span className="cta-text">Go to</span>
            </button>
          </Link>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>
          &copy; {new Date().getFullYear()} DigilabSports. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default MainLandingPage;
