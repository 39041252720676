import React, { useState } from "react";
import Header from "./loginHeader";
import Athlete from "./athlete/athlete";
import Physio from "./physio/physio";
import Manager from "./manager/manager";
import './setupGuideMainContent.css';

const SetupGuide = () => {
  const [showAthlete, setShowAthlete] = useState(false);
  const [showPhysio, setShowPhysio] = useState(false);
  const [showManager, setShowManager] = useState(false);
  return (
    <div>
      <Header />
      <div className="mainContent">
        <h2>AthlioConnect Setup Guide</h2>
        {/* Athlete Dropdown */}
        <div className="dropdown">
          <button onClick={() => setShowAthlete(!showAthlete)}>
            {showAthlete ? "Hide Athlete/Patient" : "I am an Athlete/Patient"}
          </button>
          {showAthlete && <Athlete />}
        </div>

        {/* Physio Dropdown */}
        <div className="dropdown">
          <button onClick={() => setShowPhysio(!showPhysio)}>
            {showPhysio ? "Hide Physio" : "I am a Physio"}
          </button>
          {showPhysio && <Physio />}
        </div>

        {/* Manager Dropdown */}
        <div className="dropdown">
          <button onClick={() => setShowManager(!showManager)}>
            {showManager ? "Hide Manager" : "I am a Manager"}
          </button>
          {showManager && <Manager />}
        </div>
      </div>
    </div>
  );
};

export default SetupGuide;
