import React, { useState } from "react";
import './Store.css'; // Import the CSS file
import Header from '../setupGuide/loginHeader'

const Store = () => {
    const [practitioners, setPractitioners] = useState(1);
    const [modalVisible, setModalVisible] = useState(false);
    const [showBaseFeatures, setShowBaseFeatures] = useState(false);
    const [showTier1Features, setShowTier1Features] = useState(false);
    const [showTier2Features, setShowTier2Features] = useState(false);

    const calculatePrice = (tier) => {
        let basePrice = 20;
        if (practitioners === 1) basePrice = 20;
        else if (practitioners >= 2 && practitioners <= 5) basePrice = 40;
        else if (practitioners >= 6 && practitioners <= 10) basePrice = 60;
        else basePrice = 80; // For 10+ practitioners

        if (tier === 'base') return `£${basePrice} per month`;
        if (tier === 'tier1') return `£${basePrice + 30} per month`;
        if (tier === 'tier2') return `£${basePrice + 200} per month`;
    };

    const handleSliderChange = (value) => {
        setPractitioners(value === "10" ? 11 : parseInt(value)); // 10+ handled as 11 in logic
    };

    const handleBuyNow = () => {
        setModalVisible(true); // Show the modal on Buy Now button click
    };

    const closeModal = () => {
        setModalVisible(false); // Close the modal
    };

    const toggleFeatures = (tier) => {
        if (tier === 'base') setShowBaseFeatures(!showBaseFeatures);
        if (tier === 'tier1') setShowTier1Features(!showTier1Features);
        if (tier === 'tier2') setShowTier2Features(!showTier2Features);
    };

    return (
        <div>
        <Header />
            <div className="store">
                <h2 className="store-heading">Software Store</h2>

                <div className="practitioner-slider">
                    <label htmlFor="practitioners">
                        Number of Practitioners: {practitioners > 10 ? "10+" : practitioners}
                    </label>
                    <input
                        type="range"
                        id="practitioners"
                        min="1"
                        max="10"
                        value={practitioners > 10 ? 10 : practitioners} // Shows 10+ at top
                        onChange={(e) => handleSliderChange(e.target.value)}
                        className="slider"
                    />
                </div>

                <div className="product-grid">
                    {/* Base Tier Card */}
                    <div className="product-card">
                        <h3 className="product-title">Base Tier</h3>
                        <p className="product-price">{calculatePrice('base')}</p>
                        <button className="buy-now-button" onClick={handleBuyNow}>Buy Base</button>
                        <button className="view-more-button" onClick={() => toggleFeatures('base')}>
                            {showBaseFeatures ? "View Less" : "View More"}
                        </button>
                        {showBaseFeatures && (
                            <ul className="feature-list">
                                <li>Centralised space for all programming</li>
                                <li>Personalised patient specific videos</li>
                                <li>Keep your athletes performing with integrated training and rehab programming</li>
                                <li>+ more</li>
                            </ul>
                        )}
                    </div>

                    {/* Tier 1 Card */}
                    <div className="product-card">
                        <h3 className="product-title">Tier 1</h3>
                        <p className="product-price">{calculatePrice('tier1')}</p>
                        <button className="buy-now-button" onClick={handleBuyNow}>Buy Tier 1</button>
                        <button className="view-more-button" onClick={() => toggleFeatures('tier1')}>
                            {showTier1Features ? "View Less" : "View More"}
                        </button>
                        {showTier1Features && (
                            <ul className="feature-list">
                                <li>All of base tier +</li>
                                <li>Cliniko Integration</li>
                                <li>Easy patient booking for mulitple practitioners or sites</li>
                                <li>+ more</li>
                            </ul>
                        )}
                    </div>

                    {/* Tier 2 Card */}
                    <div className="product-card">
                        <h3 className="product-title">Tier 2</h3>
                        <p className="product-price">{calculatePrice('tier2')}</p>
                        <button className="buy-now-button" onClick={handleBuyNow}>Buy Tier 2</button>
                        <button className="view-more-button" onClick={() => toggleFeatures('tier2')}>
                            {showTier2Features ? "View Less" : "View More"}
                        </button>
                        {showTier2Features && (
                            <ul className="feature-list">
                                <li>Base and tier 1 +</li>
                                <li>Practice branding (logos, colours etc)</li>
                                <li>News Letter hosting</li>
                                <li>Practice FAQ</li>
                                <li>+ more</li>
                            </ul>
                        )}
                    </div>
                </div>

                <p className="free-info">Athletes and Patients get this software for free!</p>

                {/* Modal Overlay */}
                {modalVisible && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h3>Thank You!</h3>
                            <p>Thank you for your interest. Please contact us at <a href="mailto:support@yourcompany.com">support@yourcompany.com</a> for further details.</p>
                            <button className="close-modal-button" onClick={closeModal}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Store;
