import React, { useState } from "react";
import './Athlete.css'; // Importing the CSS file

// Importing the images from the local folder
import signupImage from "../images/setup/auth0.png";
import roleSelectionImage from "../images/setup/roleAthlete.png";
import personalDetailsImage from "../images/setup/athleteProfile.png";
import joiningPhysioImage from "../images/setup/athAddPhys.png";

const Athlete = () => {
  // State to manage visibility of each section
  const [isSignUpVisible, setSignUpVisible] = useState(false);
  const [isCreateProfileVisible, setCreateProfileVisible] = useState(false);
  const [isJoinPhysioVisible, setJoinPhysioVisible] = useState(false);

  // State for modal overlay
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');

  // Function to open the modal
  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="athlete-container">
      <h3 className="title">Athlete Setup Guide</h3>

      {/* Sign Up Section */}
      <h4 
        onClick={() => setSignUpVisible(!isSignUpVisible)} 
        className="section-header"
      >
        Sign Up {isSignUpVisible ? "▲" : "▼"}
      </h4>
      {isSignUpVisible && (
        <div className="section-content">
          <ol className="step-list">
            <li>Press login</li>
            <li>Using the Auth0 login provider either login or signup</li>
          </ol>
          <img 
            src={signupImage} 
            alt="Sign Up" 
            className="step-image" 
            onClick={() => openModal(signupImage)} 
          />
          <p className="caption">Click to enlarge</p>
        </div>
      )}

      {/* Create Profile Section */}
      <h4 
        onClick={() => setCreateProfileVisible(!isCreateProfileVisible)} 
        className="section-header"
      >
        Create Profile {isCreateProfileVisible ? "▲" : "▼"}
      </h4>
      {isCreateProfileVisible && (
        <div className="section-content">
          <ol className="step-list" start="3">
            <li>Once logged in, start profile creation</li>
            <li>
              Select role - Athlete, accept the privacy policy and health disclaimer, and press submit
              <img 
                src={roleSelectionImage} 
                alt="Role Selection" 
                className="step-image" 
                onClick={() => openModal(roleSelectionImage)} 
              />
            </li>
            <li>
              Enter personal details and press create profile
              <img 
                src={personalDetailsImage} 
                alt="Personal Details" 
                className="step-image" 
                onClick={() => openModal(personalDetailsImage)} 
              />
            </li>
            <li>There can be a delay of a few seconds whilst your profile is being created. Please don't leave or refresh the page</li>
          </ol>
        </div>
      )}

      {/* Join Your Physio Section */}
      <h4 
        onClick={() => setJoinPhysioVisible(!isJoinPhysioVisible)} 
        className="section-header"
      >
        Join Your Physio {isJoinPhysioVisible ? "▲" : "▼"}
      </h4>
      {isJoinPhysioVisible && (
        <div className="section-content">
          <ol className="step-list" start="7">
            <li>Once into the app, navigate to the Physio tab in the header</li>
            <li>In the "Add to Physio Group" box, enter the physio number provided by your physio</li>
            <li>
              Once your physio has accepted your request, your physio programming and videos can be viewed in the Physio tab
              <img 
                src={joiningPhysioImage} 
                alt="Joining Physio" 
                className="step-image" 
                onClick={() => openModal(joiningPhysioImage)} 
              />
            </li>
          </ol>
        </div>
      )}

      {/* Modal for image overlay */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-modal" onClick={closeModal}>&times;</span>
            <img src={modalImageSrc} alt="Modal" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Athlete;
