import React from 'react';
import MainLandingPage from '../landingPage/mainLandingPage';

const LandingLayout = () => {
  return (
    <div>
      <MainLandingPage />
    </div>
  );
};

export default LandingLayout;
