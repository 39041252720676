import React from "react";
import "./Header.css";
import Logo from './images/logoLight.png';
import { Link } from "react-router-dom"; // assuming you're using react-router for navigation

const Header = ({ onLinkClick }) => {
  return (
    <div className="header">
      <Link to="/AthlioConnect">
        <img src={Logo} alt="Logo" className="logo" />
      </Link>
      <nav className="navbar">
        <ul>
          {/* Add the Home Icon Button */}
          <li>
            <Link to="/" className="home-icon">
              &#8962; {/* Unicode character for home icon */}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
