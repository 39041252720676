import React from "react";
import "./MerchStore.css"; // Import the CSS file
import Header from "../setupGuide/loginHeader";
import cap from './images/cap.JPG'
import bobble from './images/bobble.jpg'

const MerchStore = () => {
  return (
    <div>
      <Header />
      <div className="merch-store">
        <h2 className="store-heading">Merchandise</h2>
        <div className="product-grid">
          <div className="product-card">
            <img src={cap} alt="Cap" className="product-image" />
            <h3 className="product-title">Cap (£16)</h3>
            <button className="buy-now-button">Buy Now</button>
          </div>
          <div className="product-card">
            <img
              src={bobble}
              alt="Bobble Hat"
              className="product-image"
            />
            <h3 className="product-title">Bobble Hat (£16)</h3>
            <button className="buy-now-button">Buy Now</button>
          </div>
          <div className="product-card">
            <img src="top.jpg" alt="Top" className="product-image" />
            <h3 className="product-title">Top (coming soon)</h3>
            <button className="buy-now-button">Buy Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchStore;
