import React from 'react';
import Landing from '../AthlioConnect/landingPage';

const AthlioLayout = () => {
  return (
    <div>
      <Landing />
    </div>
  );
};

export default AthlioLayout;
