import React from 'react';
import SetupGuide from '../setupGuide/setupGuideMainContent';

const SetupGuideLayout = () => {
  return (
    <div>
      <SetupGuide />
    </div>
  );
};

export default SetupGuideLayout;
