import React from "react";
import './Contact.css';
import Header from './setupGuide/loginHeader'

const Contact = () => {
    return (
        <div>
        <Header />
            <div className="contact-container">
                <h2>Contact Us</h2>
                <div className="contact-details">
                    <p>If you have any inquiries, feel free to reach us at:</p>

                    <div className="contact-info">
                        <h3>Email:</h3>
                        <p><a href="mailto:ted@digilabsports.com">ted@digilabsports.com</a></p>

                        <h3>Phone:</h3>
                        <p>+44 7557192749</p>

                        <h3>Address:</h3>
                        <p>DigilabSports</p>
                        <p>40 Foregate Street</p>
                        <p>Kingsway House</p>
                        <p>Worcester</p>
                        <p>WR1 1EE</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
