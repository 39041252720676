import React from "react";

const Manager = () => {

    return(
        <div>
            <h3>Manager</h3>
            <p>If you are a manager please get in contact to book a setup walkthrough</p>
        </div>
    )
}

export default Manager;