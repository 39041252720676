import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router
import "./landingPage.css"; // Import CSS for styling
import Header from "../setupGuide/loginHeader";

const Landing = () => {
  return (
    <div>
      <Header />
      <div className="landing-container">
        <div className="welcome-section">
          <h2>Welcome to AthlioConnect</h2>
          <p>Your patient - physio digital interface.</p>
          <p>Bridging the Gap Between Physio and Performance.</p>
        </div>

        {/* Navigation buttons */}
        <div className="navigation-buttons">
          <Link to="/AthlioConnect/Setup-Guide">
            <button className="cta-button">Setup Guide</button>
          </Link>

          <Link to="/contact">
            <button className="cta-button">Contact Us</button>
          </Link>

          <a
            href="https://yjabkt-xa.myshopify.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="cta-button">Merch Store</button>
            <p>Merch store password: biapre</p>
          </a>

          <Link to="/AthlioConnect/store">
            <button className="cta-button">Product Store</button>
          </Link>
        </div>

        {/* About Section */}
        <div className="about-section">
          <h3>About AthlioConnect</h3>
          <p>
            AthlioConnect is a comprehensive platform designed to enhance the
            rehabilitation experience for both patients and practitioners.
          </p>
          <p>
            AthlioConnect provides a centralised space where all programming,
            videos, and resources are easily accessible. Unlike traditional
            solutions that rely on generic video banks, AthlioConnect enables
            delivering patient-specific videos tailored to individual patient
            needs.
          </p>
          <p>
            In addition to physiotherapy rehabilitation programming,
            AthlioConnect offers an "Athlete Mode" allowing athletes to create
            and manage their own gym programs. Practitioners can view, modify,
            and seamlessly integrate rehabilitation exercises into the athlete’s
            existing training plan, ensuring that rehabilitation fits smoothly
            alongside ongoing fitness goals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
